<template>
    <div class="login">
      <h2>Login</h2>
      <form @submit.prevent="login">
        <div>
          <label for="username">Username:</label>
          <input type="text" v-model="username" required autocapitalize="off" />
        </div>
        <div>
          <label for="password">Password:</label>
          <input type="password" v-model="password" required />
        </div>
        <button type="submit">Login</button>
      </form>
      <p v-if="error">{{ error }}</p>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  const API_URL=process.env.VUE_APP_API_BASE_URL;
  export default {
    data() {
      return {
        username: '',
        password: '',
        error: ''
      };
    },
    methods: {
      async login() {
        try {
          const response = await axios.post(`${API_URL}/api/login`, {
            username: this.username,
            password: this.password
          });
          localStorage.setItem('token', response.data.access_token);
          document.cookie = `token=${encodeURIComponent(response.data.access_token)}; path=/; max-age=3600`;
          this.$router.push('/fotos');
        } catch (err) {
          this.error = 'Invalid username or password';
        }
      }
    }
  };
  </script>
  
  <style>
  .login {
    max-width: 300px;
    margin: 0 auto;
    padding: 1em;
    border: 1px solid #ccc;
    border-radius: 1em;
  }
  .login div {
    margin-bottom: 1em;
  }
  .login label {
    display: block;
    margin-bottom: 0.5em;
  }
  .login input {
    width: 100%;
    padding: 0.5em;
    box-sizing: border-box;
  }
  .login button {
    padding: 0.5em;
  }
  </style>